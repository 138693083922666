import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Section, Video } from '../../components';
import { Layout } from '../../layout';
import config from '../../utils/config';
import Checklist from './checklist';
import Culture from './culture';
import Overview from './overview';
import Perks from './perks';
import Policies from './policies';
import RestrictedZone from './restricted-zone';
import Tools from './tools';
import WorkPlay from './work-play';

const Welcome: React.FC = () => {
  const [entered, setEntered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [restrictedZoneError, setRestrictedZoneError] = useState<string | null>(
    null,
  );
  const [videoSize, setVideoSize] = useState('small');

  const sections = [
    'overview',
    'culture',
    'work & play',
    'perks',
    'checklist',
    'tools',
    'policies',
  ];

  useEffect(() => {
    if (localStorage?.getItem('fidel-welcome-inside')) setEntered(true);

    checkSize();

    window.addEventListener('resize', checkSize);

    return () => window.removeEventListener('resize', checkSize);
  }, []);

  function checkSize() {
    const newVideoSize =
      window?.innerWidth < 900
        ? 'small'
        : window?.innerWidth < 1200
        ? 'mid'
        : 'large';

    setVideoSize(newVideoSize);
    setIsMobile(window?.innerWidth < 600);
  }

  function enterRestrictedZone(e: any) {
    e.preventDefault();
    const form = document?.getElementById('restricted-zone') as any;

    axios
      .post(`${config.baseUrl}/enter`, {
        pw: e?.target?.elements?.password?.value,
      })
      .then(
        res => {
          if (res.status === 200) {
            setEntered(true);
            localStorage.setItem('fidel-welcome-inside', 'true');
          } else {
            setRestrictedZoneError('Sorry, that’s not correct.');
            form?.reset();
          }
        },
        () => {
          setRestrictedZoneError('Sorry, that’s not correct.');
          form?.reset();
        },
      );
  }

  function goTo(sectionId: string) {
    const section = document?.getElementById(sectionId);
    if (section) section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    else (window as any).scrollTop = 0;
  }

  return (
    <Layout title="Employee handbook">
      {entered ? (
        <>
          <Overview sections={sections} goTo={goTo} />
          <Culture />
          <WorkPlay isMobile={isMobile} />
          <Perks />
          <Checklist />
          <Tools />
          <Policies />

          <Section className="full-width video-container">
            <figure>
              <div className="img-wrapper">
                <img
                  src={getAsset('hero_about').src}
                  srcSet={getAsset('hero_about').srcSet}
                  alt="Fidel team"
                />
                <figcaption>
                  <Video
                    src={`offsite_2018_${videoSize}.mp4`}
                    id="hero-video"
                    btnOnly
                    playBtnAlt="Team off-site in Massa Lubrense, Italy, 2018"
                    description="We filmed this video on our latest company off-site on the Amalfi Coast. We spent three days with workshops, reflecting on our past year, talking about what’s coming next, discussing how we can best achieve our goals, and of course, spending a lot of time together enjoying the Italian sun."
                  />
                  <h3>Fidel off-site 2018</h3>
                  <em>Massa Lubrense, Italy</em>
                </figcaption>
              </div>
            </figure>
          </Section>
          <Section className="inner centered">
            <h2>Welcome aboard</h2>
            <p>
              We’re excited to have you join our vibrant team. Questions?
              Comments? Concerns? Suggestions?
            </p>
            <p>
              <strong>
                Feel free to discuss with{' '}
                <a href="mailto:anna@fidel.uk" className="link-primary">
                  anna@fidel.uk
                </a>
              </strong>
            </p>
          </Section>
        </>
      ) : (
        <RestrictedZone
          handleSubmit={enterRestrictedZone}
          error={restrictedZoneError}
        />
      )}
    </Layout>
  );
};

function getAsset(name: string) {
  const src = require(`./assets/${name}.jpg`);
  const src2x = require(`./assets/${name}@2x.jpg`);
  const srcSet = `${src}, ${src2x} 2x`;

  return { src, srcSet };
}

export default Welcome;
