import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Block, Flex, Section } from '../../components/styled';

interface OverviewProps {
  sections: string[];
  goTo: (section: string) => void;
}

interface KeyFigure {
  key: string;
  value: string;
}

interface Img {
  filename: string;
  alt: string;
}

const Overview: React.FC<OverviewProps> = ({ sections, goTo }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company {
              hq
              employees {
                count
                nationalities
                languages
              }
            }
          }
        }
      }
    `,
  );

  const { hq, employees } = site.siteMetadata.company;

  const keyFigures: KeyFigure[] = [
    { key: 'HQ', value: hq },
    { key: 'Team', value: employees.count },
    { key: 'Nationalities', value: employees.nationalities },
    { key: 'Languages', value: employees.languages },
  ];

  const images: Img[] = [
    { filename: 'slideshow_17', alt: 'Friday team lunch' },
    {
      filename: 'slideshow_16',
      alt: 'Andre blowing out candles on his birthday cake',
    },
    {
      filename: 'slideshow_19',
      alt: 'Team next to the pool on our 2018 off-site',
    },
    { filename: 'slideshow_8', alt: 'Dev smiling in the back of a motorboat' },
  ];

  return (
    <>
      <Section id="overview" paddingTablet="50px 0 10px">
        <Block width="980px" className="centered">
          <h1>Employee handbook</h1>
          <Block as="p" width="740px" margin="0 auto">
            A simple guide to help you get started and understand how we work,
            play and <span className="nowrap">communicate as a team.</span>
          </Block>
          <ul className="nav-pill-list">
            {sections.map((section: string) => (
              <li key={section}>
                <button
                  onClick={() => goTo(section.replace(' & ', '-'))}
                  className="nav-pill rise-on-hover"
                  type="button"
                  data-testid={`welcome-nav-pill-${section}`}
                >
                  {section}
                </button>
              </li>
            ))}
          </ul>
        </Block>
      </Section>
      <Block
        as={Flex}
        columns={4}
        breakpoint="0px"
        width="1400px"
        margin="0 auto"
      >
        {images.map(({ filename, alt }: Img) => (
          <Block key={filename}>
            <img
              src={getAsset(filename).src}
              srcSet={getAsset(filename).srcSet}
              alt={alt}
            />
          </Block>
        ))}
      </Block>
      <Section
        maxWidth={980}
        className="two-columns overview-company-info"
        paddingTablet="50px 0 0"
      >
        <dl className="key-figures one-third">
          {keyFigures.map(({ key, value }: KeyFigure) => (
            <div key={key} className="full-width">
              <dt>{key}</dt>
              <div className="decoration-line" />
              <dd>{value}</dd>
            </div>
          ))}
        </dl>
        <div>
          <p>
            Fidel was founded in February 2013. A lot has changed over the past
            few years, apart from our mission. We are still working tirelessly
            to simplify loyalty and drive innovation. A long winding road has
            led us to Fidel API, a software platform that makes it super easy to
            link loyalty services to real-time payment events.
          </p>
          <p>
            We are proud of our one-of-a-kind relationship with global partners
            such as Visa<sup>©</sup>, Mastercard<sup>©</sup> and American
            Express<sup>©</sup>. Our presence can already be felt globally, with
            activity in European, North American and Asian-Pacific markets. One
            thing is certain, the best is yet to come! 🚀
          </p>
        </div>
      </Section>
    </>
  );
};

function getAsset(asset: string) {
  const src = require(`./assets/${asset}.png`);
  const src2x = require(`./assets/${asset}@2x.png`);
  const srcSet = `${src}, ${src2x} 2x`;

  return { src, srcSet };
}

export default Overview;
