import React from 'react';

import { Offscreen } from '../../components/styled';
import { Section } from '../../components';

interface SlideshowProps {
  isMobile: boolean;
  className?: string;
}

interface Img {
  filename: string;
  alt: string;
}

const Slideshow: React.FC<SlideshowProps> = ({ isMobile, className }) => {
  const images: Img[] = [
    { filename: 'slideshow_17', alt: 'Friday team lunch' },
    {
      filename: 'slideshow_16',
      alt: 'Andre blowing out candles on his birthday cake',
    },
    { filename: 'slideshow_2', alt: 'Our office with colourful balloons' },
    {
      filename: 'slideshow_20',
      alt: 'Sina and Les looking at a laptop screen',
    },
    { filename: 'slideshow_21', alt: 'Eszter working in the meeting room' },
    {
      filename: 'slideshow_25',
      alt: 'Nala, the office dog in front of a screen showing the new website',
    },
    { filename: 'slideshow_5', alt: 'Some of the team on a boat' },
    {
      filename: 'slideshow_19',
      alt: 'Team next to the pool on our 2018 off-site',
    },
    { filename: 'slideshow_18', alt: 'Friday team lunch' },
    { filename: 'slideshow_24', alt: 'Les working at the pingpong table' },
    {
      filename: 'slideshow_14',
      alt: 'Christmas party in the office, some people have raindeer hats',
    },
    {
      filename: 'slideshow_15',
      alt: 'Someone in a bear costume skateboarding in the office',
    },
    { filename: 'slideshow_7', alt: 'Daniele and Eszter operating a drone' },
    { filename: 'slideshow_8', alt: 'Dev smiling in the back of a motorboat' },
  ];

  const items = images.map(({ filename, alt }: Img) => {
    const { src, srcSet } = getAsset(filename);

    return <img src={src} srcSet={srcSet} alt={alt} />;
  });

  const itemsWithNoAlt = images.map(({ filename }: Img) => {
    const { src, srcSet } = getAsset(filename);

    return <img src={src} srcSet={srcSet} alt="" />;
  });

  return (
    <Section className={`inner full-width team-pictures ${className}`}>
      <Offscreen>
        <h3>Some pictures from the office and team events:</h3>
      </Offscreen>
      {isMobile ? (
        <div className="mobile-stills" data-testid="mobile-stills">
          {[3, 5].map((idx: number) => {
            const { src, srcSet } = getAsset(images[idx].filename);
            const props = {
              key: idx,
              src,
              srcSet,
              alt: images[idx].alt,
            };

            return <img {...props} />;
          })}
        </div>
      ) : (
        <div className="slideshow-container">
          {items.map((item: React.ReactNode, idx: number) => (
            <div key={`1-${idx}`}>{item}</div>
          ))}
          {itemsWithNoAlt.map((item: React.ReactNode, idx: number) => (
            <div key={`2-${idx}`} role="presentation" aria-hidden="true">
              {item}
            </div>
          ))}
        </div>
      )}
    </Section>
  );
};

function getAsset(name: string) {
  const src = require(`./assets/${name}.png`);
  const src2x = require(`./assets/${name}@2x.png`);
  const srcSet = `${src}, ${src2x} 2x`;

  return { src, srcSet };
}

export default Slideshow;
