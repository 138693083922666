import React from 'react';

import { Section } from '../../components';

const Perks: React.FC = () => {
  const perks = [
    'Vitality health insurance',
    'Perkbox benefits scheme',
    'Snacks & refreshments',
    'Friday team lunch & drinks',
    'Pension scheme',
    'Fidel apparel',
    'Dinner if you need to work late',
    'Ping pong & pool table',
  ];

  return (
    <Section id="perks" className="full-width">
      <div className="two-columns narrow inverted">
        <h2 className="one-third">Perks</h2>
        <ul className="benefits with-thumbs">
          {perks.map((perk: string, idx: number) => (
            <li key={`perk_${idx}`}>{perk}</li>
          ))}
        </ul>
      </div>
    </Section>
  );
};

export default Perks;
