import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Section } from '../../components';
import Slideshow from './slideshow';

interface Item {
  title: string;
  text: string;
}

const WorkPlay: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company {
              address {
                line1pt1
                line1pt2
                line2
                city
                postCode
              }
            }
          }
        }
      }
    `,
  );
  const { address } = site.siteMetadata.company;

  const items: Item[] = [
    {
      title: 'Headquarters',
      text: `Our office address is ${address.line1pt1}, ${
        address.line1pt2
      }, ${address.line2.replace(/ /g, '')}, ${
        address.city
      }, ${address.postCode.replace(
        ' ',
        ' ',
      )}. Feel free to send any personal parcels here!`,
    },
    {
      title: 'Working hours',
      text: 'Flexible! Weekly team meeting every Monday @ 2pm GMT. Otherwise, we are usually in the office between 9am and 6pm.',
    },
    {
      title: 'Working remotely',
      text: 'You’re welcome to work remotely from home or other parts of the world — please let the team know in advance.',
    },
    {
      title: 'Annual off-site',
      text: 'Every year we take a team trip to a sunny part of the world, usually around May. Last year it was the Amalfi Coast in Italy. Next year… who knows? One thing we know for sure is that it’ll be a blast!',
    },
    {
      title: 'Holidays',
      text: 'We don’t think it’s necessary to count them, but ask you to discuss beforehand and keep it reasonable. Just make sure you request them through CharlieHR.',
    },
  ];

  return (
    <Section id="work-play">
      <Slideshow isMobile={isMobile} />
      <Section className="narrow">
        <h2 className="centered">Work & Play</h2>
        <dl>
          {items.map(({ title, text }: Item) => (
            <div key={`wp_${title}`} className="two-columns">
              <dt className="one-third">
                <strong>{title}</strong>
              </dt>
              <dd>{text}</dd>
            </div>
          ))}
        </dl>
      </Section>
    </Section>
  );
};

export default WorkPlay;
