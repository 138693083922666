import React from 'react';

import { Section } from '../../components/styled';
import IdentityIcon from './assets/identity.svg';
import DynamicsIcon from './assets/dynamics.svg';
import FutureIcon from './assets/future.svg';

interface Card {
  icon: React.ReactNode;
  title: string;
  text: string;
}

const Culture: React.FC = () => {
  const cards: Card[] = [
    {
      icon: <IdentityIcon />,
      title: 'Identity',
      text: 'We are overachievers and innovators. We like to exceed expectations and learn fast. We are adapters and paradigm breakers. We don’t waste time on bureaucracy and nonsense. We make things happen!',
    },
    {
      icon: <DynamicsIcon />,
      title: 'Dynamics',
      text: 'We work in a truly international environment. We encourage our team to think outside of the established norms. There is no hierarchy and our structure is completely flat. We work and play as a team.',
    },
    {
      icon: <FutureIcon />,
      title: 'Future',
      text: 'It couldn’t be brighter! Our goal is to achieve 100% transaction visibility globally. We are already helping millions of customers to get rewarded on purchases and aim to continue expanding our reach across the globe.',
    },
  ];

  return (
    <Section
      id="culture"
      maxWidth={1360}
      paddingTablet="50px 30px 80px"
      paddingDesktop="50px 116px 80px"
      bgColor="#f8f8f8"
    >
      <div className="centered wide">
        <h2>Culture</h2>
        <p>Who, how and where we’re going</p>
        <dl className="three-columns">
          {cards.map(({ icon, title, text }: Card) => (
            <div className="card padded-card" key={title}>
              {icon}
              <dt>
                <h3>{title}</h3>
              </dt>
              <dd>{text}</dd>
            </div>
          ))}
        </dl>
      </div>
    </Section>
  );
};

export default Culture;
