import React from 'react';

import { Section } from '../../components';
import EqualTreatmentIcon from './assets/equal_treatment.svg';
import SecurityIcon from './assets/security.svg';
import DressCodeIcon from './assets/dress_code.svg';
import PetsIcon from './assets/pets.svg';
import FireAlarmIcon from './assets/fire_alarm.svg';
import HealthSafetyIcon from './assets/health_safety.svg';
import MaPaternityIcon from './assets/maternity.svg';
import LeavingPolicyIcon from './assets/leaving_policy.svg';
import GrievanceIcon from './assets/grievance.svg';

interface Policy {
  title: string;
  icon: React.ReactNode;
  content: React.ReactNode;
}

const Policies: React.FC = () => {
  const policies: Policy[] = [
    {
      title: 'Equal Treatment',
      icon: <EqualTreatmentIcon />,
      content:
        'We stand for equality for all, regardless of age, race, gender, orientation or disability. Treat your colleagues with the respect you’d want for yourself.',
    },
    {
      title: 'Security',
      icon: <SecurityIcon />,
      content:
        'You’ll be issued an office key and building fob upon joining. If you’re the last to leave, make sure to lock the door and switch all lights and A/C units off.',
    },
    {
      title: 'Dress Code',
      icon: <DressCodeIcon />,
      content:
        'There is none. Whatever makes you feel at home works, as long as you’re not naked. Plus you can flaunt your Fidel apparel whenever.',
    },
    {
      title: 'Pets',
      icon: <PetsIcon />,
      content:
        'We’re extremely pet-friendly, so if you own one, whether it’s a dog, cat or falcon, don’t be shy to introduce them to the team.',
    },
    {
      title: 'Fire Alarm',
      icon: <FireAlarmIcon />,
      content: (
        <span>
          In case of a fire alarm, please keep calm and carry on following
          instructions of the office fire warden. Refer to the{' '}
          <a
            href="/documents/fire_warden_guidance.pdf"
            className="link-primary"
          >
            instructions manual
          </a>
          .
        </span>
      ),
    },
    {
      title: 'Health & Safety',
      icon: <HealthSafetyIcon />,
      content:
        'We’re committed to promoting and maintaining effective health and safety practices. First Aid kit can be found in the kitchen cabinets.',
    },
    {
      title: 'Ma/Paternity Leave',
      icon: <MaPaternityIcon />,
      content:
        'Statutory. Family is important, so both moms and dads get to take time off. Speak with us if you need more flexibility.',
    },
    {
      title: 'Leaving Policy',
      icon: <LeavingPolicyIcon />,
      content:
        'Upon leaving the company, make sure to return all company equipment and coordinate handover of any ongoing tasks or projects to a relevant person.',
    },
    {
      title: 'Grievance Procedure',
      icon: <GrievanceIcon />,
      content:
        'We understand that sometimes life may throw curve balls at you. Speak to us if you need time off or if we should be made aware of any issues.',
    },
  ];

  return (
    <Section id="policies">
      <div className="wide centered">
        <h2>Company Policies</h2>
        <p>A few screws to keep our engine running smoothly</p>
        <dl className="three-columns">
          {policies.map(({ title, icon, content }: Policy, idx: number) => (
            <div key={`policy_${idx}`} className="policies-item">
              <div className="policy-icon-wrapper">{icon}</div>
              <dt>
                <h3>{title}</h3>
              </dt>
              <dd>{content}</dd>
            </div>
          ))}
        </dl>
      </div>
    </Section>
  );
};

export default Policies;
