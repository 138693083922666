import React from 'react';

import { Button } from '../../components/styled';
import { Input, InputGroup, Section } from '../../components';

interface RestrictedZoneProps {
  handleSubmit: (e: any) => void;
  error: string | null;
}

/* eslint-disable jsx-a11y/accessible-emoji */
const RestrictedZone: React.FC<RestrictedZoneProps> = ({
  handleSubmit,
  error,
}) => (
  <Section>
    <div className="inner centered">
      <h1>Employee Handbook</h1>
      <p>Psst… you’ll need the password to enter 🤫</p>
      <form id="restricted-zone" onSubmit={handleSubmit}>
        <InputGroup className="centered">
          <Input
            label="Enter password to view page"
            type="password"
            name="password"
            placeholder="Password"
          />
          <Button type="submit">Enter</Button>
        </InputGroup>
        <div className="msg-container">
          {error ? <p className="error-message">{error}</p> : null}
        </div>
      </form>
    </div>
  </Section>
);
/* eslint-enable jsx-a11y/accessible-emoji */

export default RestrictedZone;
