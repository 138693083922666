import React, { useState } from 'react';

import { Section } from '../../components';
import BoxIcon from './assets/box.svg';
import ChevronDownIcon from './assets/chevron-down.svg';
import CoffeeIcon from './assets/coffee.svg';
import SmileIcon from './assets/smile.svg';
import TopupIcon from './assets/arrow-up-circle.svg';

interface Group {
  groupName: string;
  icon: React.ReactNode;
  tools: Tool[];
}

interface Tool {
  name: string;
  link: string;
}

const Tools: React.FC = () => {
  const tools: Group[] = [
    {
      groupName: 'Essentials',
      icon: <BoxIcon />,
      tools: [
        { name: 'Slack', link: 'https://slack.com/' },
        { name: 'GSuite', link: 'https://gmail.com/' },
        { name: 'Notion', link: 'https://notion.so/' },
        { name: 'CharlieHR', link: 'https://fidel.charliehr.com/' },
        { name: 'WhatsApp', link: 'https://www.whatsapp.com/' },
        { name: '1Password', link: 'https://fidel.1password.com/signin' },
      ],
    },
    {
      groupName: 'Top-ups',
      icon: <TopupIcon />,
      tools: [
        { name: 'Zoom', link: 'https://zoom.us/' },
        { name: 'HubSpot', link: 'https://hubspot.com/' },
        { name: 'Ghost', link: 'https://blog.fidel.uk/ghost' },
        { name: 'Zeplin', link: 'https://zeplin.io/' },
        { name: 'TeamTailor', link: 'https://www.teamtailor.com/en' },
        { name: 'MetaBase', link: 'https://metabase.fidel.uk/' },
        { name: 'Fidel Dashboard', link: 'https://dashboard.fidel.uk' },
      ],
    },
    {
      groupName: 'CRMs',
      icon: <SmileIcon />,
      tools: [{ name: 'Salesforce', link: 'https://salesforce.com/' }],
    },
    {
      groupName: 'Devs',
      icon: <CoffeeIcon />,
      tools: [
        { name: 'AWS', link: 'https://console.aws.amazon.com/' },
        { name: 'GitHub', link: 'https://github.com' },
        { name: 'CircleCI', link: 'https://circleci.com/dashboard' },
      ],
    },
  ];

  return (
    <Section id="tools" className="grey-section">
      <div className="inner two-columns">
        <div className="tools-title">
          <h2>Tools</h2>
          <p>
            We don’t expect you to master all of these on day 1, but here is a
            comprehensive list of apps you may need.
          </p>
        </div>
        <div className="tools-list-container">
          {tools.map((group: Group, idx: number) => (
            <ToolsList key={`tool_category_${idx}`} group={group} />
          ))}
        </div>
      </div>
    </Section>
  );
};

const ToolsList: React.FC<{ group: Group }> = ({ group }) => {
  const { groupName, icon, tools } = group;
  const [open, toggle] = useState(groupName === 'Essentials');

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div className="inner tool-list">
      <h4
        className="tools-list-toggle"
        data-testid={`tools-list-toggle-${groupName}`}
        onClick={() => {
          toggle(!open);
        }}
        onKeyDown={() => {
          toggle(!open);
        }}
      >
        {icon}
        {groupName}
        <ChevronDownIcon className="tools-list-chevron" />
      </h4>
      <ul
        className={open ? 'open' : ''}
        data-testid={`tools-list-detail-${groupName}`}
      >
        {tools.map(({ link, name }: Tool, idx: number) => (
          <li key={`tool_${idx}`}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
};

export default Tools;
