import React from 'react';

import { Section } from '../../components';

interface ChecklistItem {
  title: string;
  content: React.ReactNode;
}

const Checklist: React.FC = () => {
  /* eslint-disable max-len */
  const checklist: ChecklistItem[] = [
    {
      title: 'Desk & Equipment',
      content: (
        <p>
          Laptop or desktop provided. If you need anything else (cables, screen,
          mouse, laptop bag, etc) please ask and we’ll order.
        </p>
      ),
    },
    {
      title: 'Office Key & Fob',
      content: (
        <p>
          You’ll be handed one of each. Please see Security section under
          Company Policies.
        </p>
      ),
    },
    {
      title: 'Email',
      content: (
        <p>
          Your new Fidel email will be set up by Andre. Check personal email for
          instructions. The whole team can be reached at{' '}
          <a href="mailto:team@fidel.uk" className="link-primary">
            team@fidel.uk
          </a>{' '}
          and any invoices should be sent to{' '}
          <a href="mailto:accounts@fidel.uk" className="link-primary">
            accounts@fidel.uk
          </a>
          .
        </p>
      ),
    },
    {
      title: 'Calendars',
      content: (
        <p>
          Add colleagues’ calendars for schedule visibility and Fidel calendar
          for the holiday placeholders. To reserve the Meeting Room or Calling
          Room, add them to your calendar invite under ‘Rooms’.
        </p>
      ),
    },
    {
      title: 'Internet',
      content: (
        <ul>
          <li>Fidel Ultimate 5G (request pw)</li>
          <li>Fidel Guests (pw: hellofidel)</li>
        </ul>
      ),
    },
    {
      title: 'Printer',
      content: (
        <p>
          Send any documents you need to print to HP M277dw via Fidel Guests
          WiFi. For scanning use USB next to printer.
        </p>
      ),
    },
  ];
  /* eslint-enable max-len */

  return (
    <Section id="checklist">
      <div className="inner narrow">
        <h2 className="centered">Day 1 checklist</h2>
        <p className="centered space-after">
          A few bits and bobs to help you hit the ground running
        </p>
        <dl className="space-after">
          {checklist.map(({ title, content }: ChecklistItem, idx: number) => (
            <div key={`checklist_${idx}`} className="checklist-item">
              <dt>
                <h3>{title}</h3>
              </dt>
              <dd>{content}</dd>
            </div>
          ))}
        </dl>
      </div>
    </Section>
  );
};

export default Checklist;
